import React from "react";

const Projects = () => {
  const open = (url) => {
    window.open(url);
  };

  return (
    <section id="projects" className="page container-fluid">
      <div className="row">
        <div
          onClick={() => open("https://buscopartido.com.ar/")}
          className="col-sm-3 red project-box">
          Busco Partido
        </div>
        <div onClick={() => open("https://chum.ar/")} className="col-sm-3 lila project-box">
          Chum.ar
        </div>
        {/* <div onClick={() => open("#")} className="col-sm-3 cold project-box">
          PDF typo
        </div> */}
        <div
          onClick={() => open("https://martingoiriz.github.io/ws-open/")}
          className="col-sm-3 blue project-box">
          WS Open
        </div>
        <div
          onClick={() => open("https://martingoiriz.github.io/empanapp/")}
          className="col-sm-3 sun project-box">
          Empanapp
        </div>
      </div>
    </section>
  );
};

export default Projects;
