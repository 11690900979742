import axios from "axios";

// const prod = process.env.REACT_APP_ENV === "dev" ? false : true;

const api_url = {
  server: "https://www.martingoiriz.com.ar/api/mg/",
};

const axiosCall = (req) => {
  let result = axios(req).then((res) => {
    return res.data;
  });
  return result;
};

export const sendContact = (data) => {
  return new Promise((resolve) => {
    const req = {
      method: "POST",
      url: api_url.server + "sendContact.php",
      data: data,
    };
    const resp = axiosCall(req);
    resolve(resp);
  });
};

export const sendSuscriber = (data) => {
  return new Promise((resolve) => {
    const req = {
      method: "POST",
      url: api_url.server + "sendSuscriber.php",
      data: data,
    };
    const resp = axiosCall(req);
    resolve(resp);
  });
};