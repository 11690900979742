import React, { useState } from "react";
import { sendSuscriber } from "../service/api";

const setAlert = (setMessage, message) => {
  setMessage(message);
  setTimeout(() => {
    setMessage(null);
  }, 3000);
}

const Newsletter = () => {
  const [message, setMessage] = useState(null);
  const [form, setForm] = useState({
    email: "",
    emailToken: Math.floor(Math.random() * 90000) + 10000,
  })

  const sendEmail = () => {
    const { email, emailToken } = form;

    if (!email || email.indexOf(".") === -1 || email.indexOf("@") === -1) {
      setAlert(setMessage, "Por favor, ingresá un email válido");
      return;
    }
    try {
      sendSuscriber({ email, emailToken }).then(({ data }) => {
        if (data === "done") {
          setAlert(setMessage, "Gracias por suscribirte! :)");
        }

        if (data === "duplicated") {
          setAlert(setMessage, "Este email ya se encuentra subscripto");
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div id="newsletter" className="page container-fluid">
      <div id="form">
        <input className='form-control form-control-lg input-suscribe' id="email" name="email" type="text" placeholder="Email" onChange={(e) => setForm({ ...form, email: e.target.value })} />
        <button className="btn btn-suscribe" onClick={sendEmail}>Suscribirme</button>
      </div>
      {message && <div id="success" className="container">
        {message}
      </div>}
    </div>
  );
};

export default Newsletter;
