import React from "react";
import { IoLogoGithub, IoLogoLinkedin } from "react-icons/io5";

const Home = () => {
  const open = (e) => window.open(e);

  return (
    <div id="home" className="page container-fluid">
      <ul className="col-left">
        <li onClick={() => open("https://github.com/martingoiriz")}>
          <IoLogoGithub />
        </li>
        <li onClick={() => open(" https://www.linkedin.com/in/martin-goiriz/")}>
          <IoLogoLinkedin />
        </li>
      </ul>
      <div className="brand">
        <h1 className="name">Martín Goiriz</h1>
        <h2>Full Stack Dev</h2>
      </div>
    </div>
  );
};

export default Home;
