import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Header from "./components/header";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Projects from "./pages/projects";
import Newsletter from './pages/newsletter'

export const AppContext = React.createContext();

const App = () => {
  const isNewsletter = window.location.pathname.includes('newsletter')

  return (
    <BrowserRouter>
      {!isNewsletter && <Header />}
      <Route path="/" exact component={Home} />
      <Route path="/contacto" exact component={Contact} />
      <Route path="/proyectos" exact component={Projects} />
      <Route path="/newsletter" exact component={Newsletter} />
    </BrowserRouter>
  );
};

export default App;
