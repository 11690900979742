import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";

const Header = () => {
  let [active, setActive] = useState(1);
  let [showMenu, setShowMenu] = useState(false);

  function showSideMenu() {
    setShowMenu(!showMenu);
  }

  function toggleClass(value) {
    setActive(value);
    setShowMenu(false);
  }

  function blogClick() {
    window.open("https://www.martingoiriz.com.ar/blog");
  }

  return (
    <header className="header-component">
      <nav className="navbar navbar-expand-lg ">
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={showSideMenu}>
          <IoIosMenu className="menu-icon" />
        </button>
        <div
          className={`collapse navbar-collapse ${showMenu ? "show" : " "}`}
          id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                onClick={toggleClass.bind(this, 1)}
                to="/"
                className={`nav-item nav-link ${active === 1 ? "active" : "inactive"}`}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <span className="nav-item nav-link" onClick={() => blogClick()}>
                Blog
              </span>
            </li>
            <li className="nav-item">
              <Link
                onClick={toggleClass.bind(this, 4)}
                to="/proyectos"
                className={`nav-item nav-link ${active === 4 ? "active" : "inactive"}`}>
                Proyectos
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={toggleClass.bind(this, 3)}
                to="/contacto"
                className={`nav-item nav-link ${active === 3 ? "active" : "inactive"}`}>
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
