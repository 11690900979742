import React, { useState } from "react";
import { sendContact } from "../service/api";

const Contact = () => {
  const [data, setData] = useState({
    email: null,
    name: null,
    msg: null,
  });

  const sendMessage = () => {
    console.log(data);
    if (data.email.indexOf(".") === -1 || data.email.indexOf("@") === -1) {
      alert("Por favor, ingresá un email válido");
      return false;
    }

    if (data.name && data.email && data.msg) {
      sendContact(data).then((res) => {
        console.log(res);
        if (res.data === "done") {
          setData({
            name: "",
            email: "",
            msg: "",
          });
          alert("¡Mensaje enviado!");
        }
      });
    } else {
      alert("Por favor, completá todos los campos.");
    }
  };

  return (
    <section id="contacto" className="page container-fluid">
      <div className="row titles">
        <div className="col-12 text-center">
          <h2>Contacto</h2>
        </div>
        <div className="col-12 description">
          <p>Dejame tu mensaje completando este formulario y te responderé a la brevedad</p>
        </div>
      </div>
      <main className="contenido_contacto">
        <div className="formulario">
          <input
            onChange={(e) => setData({ ...data, name: e.target.value })}
            type="text"
            name="name"
            id="name"
            placeholder="Nombre"
          />
          <input
            onChange={(e) => setData({ ...data, email: e.target.value })}
            type="email"
            name="email"
            id="email"
            placeholder="Email"
          />
          <textarea
            cols="5"
            rows="5"
            onChange={(e) => setData({ ...data, msg: e.target.value })}
            maxLength="500"
            id="message"
            name="message"
            placeholder="Mensaje"></textarea>
          <button onClick={sendMessage} id="send" className="send">
            Enviar
          </button>
        </div>
      </main>
    </section>
  );
};

export default Contact;
